import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../Layout"

const page = () => {
    return (
        <Layout>

            <Helmet>
                <title>Sivua ei löytynyt – Puhelinliittymat.fi</title>
            </Helmet>

            <main>
                <div className="section purpleBackground isFirstSection" style={{ minHeight: "500px" }}>
                    <div className="container">
                        <div className="innerPadding">
                            <h1>Sivua ei löytynyt</h1>
                            <p>Voit siirtyä etusivulle <Link to="/">tästä linkistä</Link>.</p>
                        </div>
                    </div>
                </div>
            </main>

        </Layout>
    )
}

export default page
